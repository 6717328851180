<template>
  <MediaInterpreter :media="computedMedia" />
</template>

<script setup lang="ts">
import { type CmsElement } from "@/types/cms";
import { type Media } from "@shopware-pwa/types";
import MediaInterpreter from "@/components/atoms/MediaInterpreter";

const props = defineProps<{
  content: CmsElement<{
    mediaHevc?: Media;
    mediaAv1?: Media;
    mediaWebm?: Media;
    mediaH264?: Media;
  }>;
}>();

const computedMedia = computed(() => {
  return {
    hevc: props.content.config?.mediaHevc?.value,
    av1: props.content.config?.mediaAv1?.value,
    webm: props.content.config?.mediaWebm?.value,
    h264: props.content.config?.mediaH264?.value,
  };
});
</script>
